import React from "react"
import { Link } from "gatsby"

import StarIcon from "../../assets/images/star-icon.png"

const Solutions = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={StarIcon} alt="StarIcon" />
            Our Solutions
          </span>
          <h2>We Different From Others Should Choose Us</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>
                <Link to="/service-details-two">Startup Applications</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>

              <Link to="/service-details-two" className="view-details-btn">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>
              <h3>
                <Link to="/service-details-two">SaaS Solutions</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>

              <Link to="/service-details-two" className="view-details-btn">
                View Details
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>
              <h3>
                <Link to="/service-details-two">eCommerce Platforms</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>

              <Link to="/service-details-two" className="view-details-btn">
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Solutions
