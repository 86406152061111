import React from "react"
import ReactWOW from "react-wow"

const Partner = () => {
  return (
    <div className="partner-area pt-100 pb-70 bg-f1f8fb">
      <div className="container"></div>

      <div className="divider"></div>
    </div>
  )
}

export default Partner
